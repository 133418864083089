const versionData = {
    "1.1": {
        overworld: 4.17,
        nether: 3.98,
        end: 3.98
    },
    "1.8.8": {
        overworld: 5.89,
        nether: 3.98,
        end: 3.98
    },
    "1.12.2": {
        overworld: 6.12,
        nether: 3.99,
        end: 3.98
    },
    "1.16.5": {
        overworld: 6.79,
        nether: 7.31,
        end: 4.3
    },
    "1.17.1": {
        overworld: 8.35,
        nether: 6.54,
        end: 4.29
    },
    "1.18": {
        overworld: 11.72,
        nether: 7.12,
        end: 4.31
    },
    "1.19": {
        overworld: 11.42,
        nether: 6.83,
        end: 4.31
    },
    "1.20.1": {
        overworld: 11.39,
        nether: 6.35,
        end: 4.31
    },
    "1.21": {
        overworld: 11.38,
        nether: 6.33,
        end: 4.31
    }
}
export default versionData;